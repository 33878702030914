.carousel-control{
    background-color: $dark-gray;
    border-radius: 50%;
    opacity: 70%;
    text-shadow: none;

    &:hover,
    &:focus{
        opacity: 100%;
        background-color: $dark-gray,
        ;
    }
}

.carousel-control.left{
    height: 30px;
    top: 50%;
    width: 30px;
    left: 25px;
}
.carousel-control.right{
    height: 30px;
    right: 25px;
    top: 50%;
    width: 30px;
}
.carousel-control .icon-prev, .carousel-control .icon-next, .carousel-control .fa, .carousel-control .fa {
    display: inline-block;
    z-index: 5;
}
.carousel-control .fa{
    font-size: 26px;
    margin: 2px;
}
.carousel-control.left, .carousel-control.right {
    background-image: none;
}

 .page-carousel{
    border-radius: $border-radius-extreme !important;
    border: none !important;
    width: 100%;
}

.carousel-inner {
  > .carousel-item > img,
  > .carousel-item > a > img {
      box-shadow: 0 2px 2px rgba(204, 197, 185, 0.5);
  }
  .carousel-item img {
    width: 100%;
  }

}

.carousel-caption{
    .title,
    .info-title{
        color: $white-color;
    }
}
.carousel-caption{
    margin-bottom: 10%;
}

.carousel-caption > h3 {
    font-size: 4em;
    line-height: 1.5em;
    text-shadow: 0 0 10px black;
}

.carousel-caption > p {
    font-size: 1.2em;
    text-shadow: 0 0 5px black;
}

.carousel-indicators > li{
    background-color: rgba(204, 204, 204, 0.49);
    border: 0 none;
}
.carousel-indicators .active{
    background-color: $koeuca-sagegree-light;
}
.page-carousel:hover{
    .carousel-control.right,
    .carousel-control.left{
        opacity: 1;
        z-index: 1;
    }
}
.card {
    .carousel {
        .carousel-indicators {
            bottom: 0;
        }
        a {
            position: absolute;
            color: $white-color !important;
        &:hover {
            color: $white-color !important;
        }
        .fa-angle-left:before {
            padding-right: 4px;
        }
        .fa-angle-right:before {
            padding-left: 4px;
        }
        }
    }
}
